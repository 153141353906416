import React from 'react';
import { Container } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import EmptyLayout from '../components/Layout/EmptyLayout';
import Selector from '../components/Selector/Selector';

export default () => {
  const result = useStaticQuery(graphql`
    {
      contentfulToolSelectorBody(
        contentful_id: { eq: "2fZzeSrHbaRhSikhnRU1Fv" }
      ) {
        header
        body {
          raw
        }
        options {
          optionDisplayTitle
          resultTag
          body {
            header
            body {
              raw
            }
            options {
              optionDisplayTitle
              resultTag
              body {
                header
                body {
                  raw
                }
                options {
                  resultTag
                  optionDisplayTitle
                  body {
                    header
                    body {
                      raw
                    }
                    options {
                      resultTag
                      optionDisplayTitle
                      body {
                        header
                        body {
                          raw
                        }
                        options {
                          optionDisplayTitle
                          resultTag
                          body {
                            header
                            body {
                              raw
                            }
                            options {
                              optionDisplayTitle
                              resultTag
                              body {
                                header
                                body {
                                  raw
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allContentfulToolSelectorResultGroup {
        nodes {
          resultTag
          resultItems {
            resultId
            resultTitle
            link
            description {
              raw
            }
          }
        }
      }
    }
  `);

  const data = result.contentfulToolSelectorBody;

  const resultData = result.allContentfulToolSelectorResultGroup.nodes;

  return (
    <EmptyLayout>
      <Container fluid>
        <Selector data={data} resultData={resultData} />
      </Container>
    </EmptyLayout>
  );
};
